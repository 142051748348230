import React, { useState, useEffect, createRef } from 'react';
import { db } from '../../../config/firebase';
import { ref, onChildChanged } from 'firebase/database';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import { Typography } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/system/Unstable_Grid';
import { TextField, MenuItem, Autocomplete } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell, ResponsiveContainer, LabelList } from 'recharts';
import { RadialBarChart, RadialBar, Legend } from 'recharts';
import Swal from 'sweetalert2';
import groupBy from 'lodash.groupby';
import orderby from 'lodash.orderby';
import SectionTitle from '../../../components/SeccionTitle';
import { getCollections, stringToArray, getDate, cualMes } from '../../../assets/js/functions';
import { SatisfaccionOperativa } from '../../../assets/js/reportes';
import './styles.scss';

const style = {
	top: '50%',
	right: '0%',
	fontSize: '14px',
	transform: 'translate(0, -50%)',
	lineHeight: '24px',
};

export default function ReporteEncuestaDeSatisfaccionOperativa() {
	const [datos, setDatos] = useState([]);
	const [servicios, setServicios] = useState([]);
	const [clientes, setClientes] = useState([]);
	const [proyectos, setProyectos] = useState([]);
	const [ejecucion, setEjecucion] = useState([]);
	// const [startDate, setStartDate] = useState(null);
	// const [endDate, setEndDate] = useState(null);
	const [show, setShow] = useState(false);
	const [oldDate, setOldDate] = useState(null);
	const [today, setToday] = useState(null);

	// nps
	const [nps, setNps] = useState([]);
	const [npsPromedio, setNpsPromedio] = useState(0);
	// satisfaccion general
	const [satisfaccionGeneral, setSatisfaccionGeneral] = useState([]);
	const [satisfaccionGeneralPromedio, setSatisfaccionGeneralPromedio] = useState(0);

	const reference = createRef(null);
	const reference2 = createRef(null);
	const reference3 = createRef(null);
	const reference4 = createRef(null);
	const [image, takeScreenshot] = useScreenshot();
	const [image2, takeScreenshot2] = useScreenshot();
	const [image3, takeScreenshot3] = useScreenshot();
	const [image4, takeScreenshot4] = useScreenshot();

	const download = (image, { name = 'satisfaccion-operativa-nps', extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image;
		a.download = createFileName(extension, name);
		a.click();
	};

	const download2 = (image2, { name = 'satisfaccion-operativa', extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image2;
		a.download = createFileName(extension, name);
		a.click();
	};

	const download3 = (image3, { name = 'satisfaccion-operativa-servicios', extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image3;
		a.download = createFileName(extension, name);
		a.click();
	};

	const download4 = (image4, { name = 'satisfaccion-operativa-ejecuciones-entregables', extension = 'png' } = {}) => {
		const a = document.createElement('a');
		a.href = image4;
		a.download = createFileName(extension, name);
		a.click();
	};

	const getImage = async () => {
		let a = reference.current;
		setTimeout(async () => {
			await takeScreenshot(a).then(download);
			setShow(false);
		}, 1000);
	};

	const getImage2 = () => {
		let a = reference2.current;
		setTimeout(async () => {
			await takeScreenshot2(a).then(download2);
			setShow(false);
		}, 1000);
	};

	const getImage3 = () => {
		let a = reference3.current;
		setTimeout(async () => {
			await takeScreenshot3(a).then(download3);
			setShow(false);
		}, 1000);
	};

	const getImage4 = () => {
		let a = reference4.current;
		setTimeout(async () => {
			await takeScreenshot4(a).then(download4);
			setShow(false);
		}, 1000);
	};

	const COLORS = ['#5499C7 ', '#EC7063', '#C39BD3', '#48C9B0', '#CD6155', '#F1948A', '#EB984E', '#FF8042', '#00C49F', '#F9E79F'];

	const get = async (cliente = null, proyecto = null) => {
		let terminados = [],
			arrayClientes = [],
			arrayProyetos = [];
		const resultados = await getCollections('pruebas');
		Object.values(resultados).map((val) => {
			arrayClientes.push(val.preguntaUno);
			if (val.status === 'Terminado') {
				if (cliente === null) {
					terminados.push(val);
				} else {
					if (cliente === val.preguntaUno && proyecto === null) {
						arrayProyetos.push(val.preguntaCuatro);
						terminados.push(val);
					} else if (cliente === val.preguntaUno && proyecto === val.preguntaCuatro) {
						terminados.push(val);
					}
				}
			}
		});
		setProyectos(arrayProyetos.sort());
		const orderClientes = groupBy(resultados, 'preguntaUno');
		const keysOrderlientes = Object.keys(orderClientes);
		setClientes(keysOrderlientes);
		if (terminados.length > 0) {
			const orderData = groupBy(terminados, 'preguntaTres');
			// old date
			const orderDate = orderby(terminados, ['fechaRegistroConsultor'], ['asc']);
			const date = orderDate[0].fechaRegistroConsultor;
			const dateSubstring = date.substring(0, 10);
			const arrayDate = stringToArray(dateSubstring, '-');
			const dateFormatter = `${arrayDate[2]}-${cualMes(arrayDate[1])}-${arrayDate[0]}`;

			// today date
			const today = getDate();
			const todaySubString = today.substring(0, 10);
			const todayDate = stringToArray(todaySubString, '-');
			const todayFormatter = `${todayDate[2]}-${cualMes(todayDate[1])}-${todayDate[0]}`;

			setToday(todayFormatter);
			setOldDate(dateFormatter);
			setDatos(orderData);
			getNps(orderData);
			satisfaccion(orderData);
			countServicios(terminados);
			countEjecuciones(terminados);
		} else {
			getNps([]);
			satisfaccion([]);
			countServicios([]);
			countEjecuciones([]);
		}

		// satisfaccionConsultor(resultados);
	};

	const getNps = async (json) => {
		const array = [];
		Object.keys(json).map((key) => {
			const values = json[key];
			let jsonResult = {};
			let suma = 0;
			let promedio = 0;
			let totalEncuestas = 0;
			if (values) {
				const valuess = Object.keys(values);
				Object.values(valuess).map((val) => {
					const data = json[key][val];
					if (key === data.preguntaTres) {
						totalEncuestas += Object.keys(data.respuestas).length;
						Object.values(data.respuestas).map((valRes) => {
							suma += parseInt(valRes.preguntaDieciseis);
							promedio = suma / totalEncuestas;
							jsonResult = {
								name: data.preguntaTres,
								promedio: parseFloat(promedio.toFixed(2)),
								encuestas: totalEncuestas,
							};
						});
					} else {
						suma = 0;
						promedio = 0;
					}
				});
			}
			array.push(jsonResult);
		});
		setNps(array);
		let sumaPromedio = 0;
		Object.values(array).map((val) => {
			if (val.promedio && val.promedio > 0) {
				sumaPromedio += val.promedio;
			}
		});

		const promedioGeneral = sumaPromedio / array.length;
		let entero = promedioGeneral;
		if (promedioGeneral < 10) {
			entero = promedioGeneral.toFixed(2);
		}
		setNpsPromedio(entero);
	};

	const satisfaccion = async (json) => {
		const array = [];
		Object.keys(json).map((key) => {
			const values = json[key];
			let jsonResult = {};
			let suma = 0;
			let promedio = 0;
			let totalEncuestas = 0;
			if (values) {
				const valuess = Object.keys(values);
				Object.values(valuess).map((val) => {
					const data = json[key][val];
					if (key === data.preguntaTres) {
						totalEncuestas += Object.keys(data.respuestas).length;

						Object.values(data.respuestas).map((valRes) => {
							suma +=
								parseInt(valRes.preguntaCinco) +
								parseInt(valRes.preguntaSeis) +
								parseInt(valRes.preguntaSiete) +
								parseInt(valRes.preguntaOcho) +
								parseInt(valRes.preguntaNueve) +
								parseInt(valRes.preguntaDiez) +
								parseInt(valRes.preguntaDoce) +
								parseInt(valRes.preguntaTrece) +
								parseInt(valRes.preguntaQuince);

							promedio = suma / totalEncuestas / 9;
							jsonResult = {
								name: data.preguntaTres,
								promedio: parseFloat(promedio.toFixed(2)),
								encuestas: totalEncuestas,
							};
						});
					} else {
						suma = 0;
						promedio = 0;
					}
				});
			}
			array.push(jsonResult);
		});
		setSatisfaccionGeneral(array);
		let sumaPromedio = 0;
		Object.values(array).map((val) => {
			sumaPromedio += val.promedio;
		});

		const promedioGeneral = sumaPromedio / array.length;
		let entero = promedioGeneral;
		if (promedioGeneral < 5) {
			entero = promedioGeneral.toFixed(2);
		}
		setSatisfaccionGeneralPromedio(entero);
	};

	// const busqueda = () => {
	// 	let bd = datos;
	// 	let array = [];

	// 	// por fechas
	// 	if (startDate === null) {
	// 		Swal.fire({
	// 			icon: 'error',
	// 			title: 'Error',
	// 			text: 'Ingresa una fecha inicial',
	// 			confirmButtonText: 'Cerrar',
	// 			confirmButtonColor: '#c1212a',
	// 		}).then((result) => {
	// 			if (result.isConfirmed) {
	// 				get();
	// 			}
	// 		});
	// 		return null;
	// 	} else {
	// 		if (endDate === null) {
	// 			Swal.fire({
	// 				icon: 'error',
	// 				title: 'Error',
	// 				text: 'Ingresa una fecha final',
	// 				confirmButtonText: 'Cerrar',
	// 				confirmButtonColor: '#c1212a',
	// 			}).then((result) => {
	// 				if (result.isConfirmed) {
	// 					get();
	// 				}
	// 			});
	// 			return null;
	// 		} else {
	// 			Object.keys(bd).map((key) => {
	// 				const values = bd[key];
	// 				if (values) {
	// 					const valuess = Object.keys(values);
	// 					Object.values(valuess).map((val) => {
	// 						const data = bd[key][val];
	// 						if (key === data.preguntaTres) {
	// 							const fechaArray = stringToArray(data.fechaRegistroCliente, ' ');
	// 							if (fechaArray[0] >= startDate && fechaArray[0] <= endDate) {
	// 								array.push(data);
	// 							}
	// 						}
	// 					});
	// 				}
	// 			});
	// 		}
	// 	}
	// 	const orderData = groupBy(array, 'preguntaTres');
	// 	// console.log(orderData);
	// 	setDatos(orderData);
	// 	getNps(orderData);
	// 	satisfaccion(orderData);
	// };

	const limpiar = () => {
		// get();
		// setStartDate('dd/mm/aaaa');
		// setEndDate('dd/mm/aaaa');
		window.location.reload();
	};

	useEffect(() => {
		get();
		const dbRef = ref(db, 'pruebas/');
		onChildChanged(dbRef, (data) => {
			get();
		});
	}, []);

	const countServicios = async (resultados) => {
		let array = [];
		let opcion1 = 0,
			opcion2 = 0,
			opcion3 = 0,
			opcion4 = 0,
			opcion5 = 0,
			opcion6 = 0,
			opcion7 = 0,
			opcion8 = 0;
		Object.values(resultados).map((val) => {
			if (val.status === 'Terminado') {
				Object.values(val.respuestas).map((valRes) => {
					if (valRes.preguntaOnce.opcion1 !== '') {
						opcion1++;
					}
					if (valRes.preguntaOnce.opcion2 !== '') {
						opcion2++;
					}
					if (valRes.preguntaOnce.opcion3 !== '') {
						opcion3++;
					}
					if (valRes.preguntaOnce.opcion4 !== '') {
						opcion4++;
					}
					if (valRes.preguntaOnce.opcion5 !== '') {
						opcion5++;
					}
					if (valRes.preguntaOnce.opcion6 !== '') {
						opcion6++;
					}
					if (valRes.preguntaOnce.opcion7 !== '') {
						opcion7++;
					}
					if (valRes.preguntaOnce.opcion8 !== '') {
						opcion8++;
					}
				});
			}
		});
		array.push(
			{
				name: 'Comunicación abierta',
				cantidad: opcion1,
				fill: '#8884d8',
			},
			{
				name: 'Asesoramiento durante todo el proyecto',
				cantidad: opcion2,
				fill: '#83a6ed',
			},
			{
				name: 'Trato personalizado',
				cantidad: opcion3,
				fill: '#8dd1e1',
			},
			{
				name: 'Flexibilidad y adaptación al cambio',
				cantidad: opcion4,
				fill: '#82ca9d',
			},
			{
				name: 'Escucha activa y apertura a la retroalimentación',
				cantidad: opcion5,
				fill: '#a4de6c',
			},
			{
				name: 'Amabilidad y cordialidad',
				cantidad: opcion6,
				fill: '#58D68D',
			},
			{
				name: 'Agilidad para ofrecer alternativas y soluciones',
				cantidad: opcion7,
				fill: '#ffc658',
			},
			{
				name: 'Otros',
				cantidad: opcion8,
				fill: '#FF5284',
			}
		);
		setServicios(array);
	};

	const countEjecuciones = async (resultados) => {
		let array = [];
		let opcion1 = 0,
			opcion2 = 0,
			opcion3 = 0,
			opcion4 = 0,
			opcion5 = 0,
			opcion6 = 0,
			opcion7 = 0,
			opcion8 = 0;
		Object.values(resultados).map((val) => {
			if (val.status === 'Terminado') {
				Object.values(val.respuestas).map((valRes) => {
					if (valRes.preguntaCatorce.opcion1 !== '') {
						opcion1++;
					}
					if (valRes.preguntaCatorce.opcion2 !== '') {
						opcion2++;
					}
					if (valRes.preguntaCatorce.opcion3 !== '') {
						opcion3++;
					}
					if (valRes.preguntaCatorce.opcion4 !== '') {
						opcion4++;
					}
					if (valRes.preguntaCatorce.opcion5 !== '') {
						opcion5++;
					}
					if (valRes.preguntaCatorce.opcion6 !== '') {
						opcion6++;
					}
					if (valRes.preguntaCatorce.opcion7 !== '') {
						opcion7++;
					}
					if (valRes.preguntaCatorce.opcion8 !== '') {
						opcion8++;
					}
				});
			}
		});
		array.push(
			{
				name: 'Aceptables',
				cantidad: opcion1,
				fill: '#8884d8',
			},
			{
				name: 'Atractivos',
				cantidad: opcion2,
				fill: '#83a6ed',
			},
			{
				name: 'Impecables',
				cantidad: opcion3,
				fill: '#8dd1e1',
			},
			{
				name: 'Innovadores/Diferentes',
				cantidad: opcion4,
				fill: '#82ca9d',
			},
			{
				name: 'Creativos',
				cantidad: opcion5,
				fill: '#a4de6c',
			},
			{
				name: 'Funcionales',
				cantidad: opcion6,
				fill: '#58D68D',
			},
			{
				name: 'Centrados en el usuario',
				cantidad: opcion7,
				fill: '#ffc658',
			},
			{
				name: 'Otros',
				cantidad: opcion8,
				fill: '#FF5284',
			}
		);
		setEjecucion(array);
	};

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className='custom-tooltip'>
					<p className='label'>{`${label} : ${payload[0].value}`}</p>
					<p className='label'>{`Encuestas : ${payload[0].payload.encuestas}`}</p>
				</div>
			);
		}
		return null;
	};

	return (
		<>
			<div className='content-area'>
				<SectionTitle title='Satisfacción Operativa' />
				<section id='ReporteEncuestaDeSatisfaccionOperativa' className='mt30'>
					<Grid container direction='row' alignItems='center' spacing={2}>
						{/* <Grid item lg={3} md={3} sm={6} xs={12}>
							<FormControl fullWidth variant='filled' value={startDate} onChange={(e) => setStartDate(e.target.value)}>
								<FilledInput id='outlined-adornment-user' type='date' autoComplete={false} />
							</FormControl>
						</Grid>
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<FormControl fullWidth variant='filled' value={endDate} onChange={(e) => setEndDate(e.target.value)}>
								<FilledInput id='outlined-adornment-user' type='date' autoComplete={false} />
							</FormControl>
						</Grid>
						<Grid item lg={2} md={2} sm={12} xs={12}>
							<div className='center'>
								<div variant='info' className='btn' onClick={() => busqueda()}>
									Filtrar
								</div>
							</div>
						</Grid> */}
						<Grid item lg={2} md={2} sm={12} xs={12}>
							<div className='center'>
								<div variant='info' className='btn-two' onClick={limpiar}>
									Reset
								</div>
							</div>
						</Grid>
						<Grid item lg={2} md={2} sm={12} xs={12}>
							<div className='center'>
								<div variant='info' className='btn' onClick={SatisfaccionOperativa}>
									Reporte
								</div>
							</div>
						</Grid>
					</Grid>
					<Grid container direction='row' alignItems='center' spacing={2} style={{ marginTop: '10px' }}>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Autocomplete options={clientes} onChange={(event, value) => get(value)} renderInput={(params) => <TextField fullWidth {...params} label='Selecciona un cliente para filtrar...' />} />
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<Autocomplete options={proyectos} onChange={(event, value) => get(value)} renderInput={(params) => <TextField fullWidth {...params} label='Selecciona un proyecto para filtrar...' />} />
						</Grid>
					</Grid>

					<Grid container direction='row' alignItems='stretch' spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<div ref={reference} style={{ padding: '15px' }}>
								<Typography variant='h5' color='text' colorBrightness='secondary' style={{ marginBottom: '30px' }}>
									{`NPS ${npsPromedio > 0 ? npsPromedio : 0} / 10`}
									{show && <span style={{ float: 'right' }}>{`${oldDate} / ${today}`}</span>}
								</Typography>
								<ResponsiveContainer width='100%' height={450}>
									<BarChart
										data={nps}
										margin={{
											top: 0,
											right: 0,
											left: 0,
											bottom: 0,
										}}
									>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis dataKey='name'></XAxis>
										<YAxis domain={[0, 5]} ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]} />
										<Tooltip content={<CustomTooltip />} />
										<Bar dataKey='promedio' fill='#0088FE'>
											{nps.map((entry, index) => (
												<>
													<Cell style={{ cursor: 'pointer' }} key={`cell-${index}`} fill={COLORS[(Math.random() * COLORS.length) | 0]} />
												</>
											))}
											<LabelList fill='#000' dataKey='promedio' position='centerTop' />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</div>
							<div className='full center'>
								<div
									variant='info'
									className='btn'
									onClick={() => {
										setShow(!show);
										getImage();
									}}
								>
									Descargar gráfica
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<div ref={reference2} style={{ padding: '15px' }}>
								<Typography variant='h5' color='text' colorBrightness='secondary' style={{ marginBottom: '30px' }}>
									{`SATISFACCIÓN GENERAL ${satisfaccionGeneralPromedio > 0 ? (satisfaccionGeneralPromedio > 5 ? 5 : satisfaccionGeneralPromedio) : 0} / 5`}
									{show && <span style={{ float: 'right' }}>{`${oldDate} / ${today}`}</span>}
								</Typography>
								<ResponsiveContainer width='100%' height={450}>
									<BarChart data={satisfaccionGeneral}>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis dataKey='name'></XAxis>
										<YAxis domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} />
										<Tooltip content={<CustomTooltip />} />
										<Bar dataKey='promedio' fill='#00C49F'>
											{satisfaccionGeneral.map((entry, index) => (
												<>
													<Cell style={{ cursor: 'pointer' }} key={`cell-${index}`} fill={COLORS[(Math.random() * COLORS.length) | 0]} />
												</>
											))}
											<LabelList fill='#000' dataKey='promedio' position='centerTop' />
										</Bar>
									</BarChart>
								</ResponsiveContainer>
							</div>
							<div className='full center'>
								<div
									variant='info'
									className='btn'
									onClick={() => {
										setShow(!show);
										getImage2();
									}}
								>
									Descargar gráfica
								</div>
							</div>
						</Grid>
					</Grid>
					<Grid container direction='row' alignItems='stretch' spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<div id='servicios' ref={reference3} style={{ padding: '15px' }}>
								<Typography variant='h5' color='text' colorBrightness='secondary' style={{ marginBottom: '30px' }}>
									{`SERVICIO`}
									{show && <span style={{ float: 'right' }}>{`${oldDate} / ${today}`}</span>}
								</Typography>
								<ResponsiveContainer width='100%' height={450}>
									<RadialBarChart cx='30%' cy='50%' innerRadius='20%' outerRadius='100%' barSize={100} data={servicios}>
										<RadialBar barSize={30} minAngle={0} label={{ position: 'insideTop', fill: '#000 !import', fontSize: '14px', fontWeight: 'bold' }} background clockWise dataKey='cantidad' />
										<Legend iconSize={10} layout='vertical' verticalAlign='middle' wrapperStyle={style} />
									</RadialBarChart>
								</ResponsiveContainer>
							</div>
							<div className='full center'>
								<div
									variant='info'
									className='btn'
									onClick={() => {
										setShow(!show);
										getImage3();
									}}
								>
									Descargar gráfica
								</div>
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6}>
							<div id='servicios' ref={reference4} style={{ padding: '15px' }}>
								<Typography variant='h5' color='text' colorBrightness='secondary' style={{ marginBottom: '30px' }}>
									{`CALIDAD EJECUCIÓN / ENTREGABLES`}
									{show && <span style={{ float: 'right' }}>{`${oldDate} / ${today}`}</span>}
								</Typography>
								<ResponsiveContainer width='100%' height={450}>
									<RadialBarChart cx='40%' cy='50%' innerRadius='20%' outerRadius='100%' barSize={100} data={ejecucion}>
										<RadialBar barSize={30} minAngle={0} label={{ position: 'insideTop', fill: '#000 !import', fontSize: '14px', fontWeight: 'bold' }} background clockWise dataKey='cantidad' />
										<Legend iconSize={10} layout='vertical' verticalAlign='middle' wrapperStyle={style} />
									</RadialBarChart>
								</ResponsiveContainer>
							</div>
							<div className='full center'>
								<div
									variant='info'
									className='btn'
									onClick={() => {
										setShow(!show);
										getImage4();
									}}
								>
									Descargar gráfica
								</div>
							</div>
						</Grid>
					</Grid>
				</section>
			</div>
		</>
	);
}
